import React from "react";

import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { useTranslation } from "react-i18next";
import { Link, useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import AlbumLayout,  {useStyles} from './album_layout';
import { useTheme } from "@material-ui/core/styles";
import { MoreButton } from "./more_button";
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { createAnswer } from "../graphql/mutations";
import References from './references';




const Personas = (props:any ) => {
  const theme = useTheme();

  const personas = Object.values(props.pageContext.personas);
  const carouselItems = Object.values(props.pageContext.carousel);
  const pagePath = props.pageContext.pagePath;

  const { t } = useTranslation();
  const classes = useStyles();

  const description = `${t("welcome-2")}. ${t("welcome-3")}`;
  const title = t("meta-title");

  const [user, setUser] = React.useState({});


  // if we have a user, update server w/ local answers
  React.useEffect(() => {
    let isMounted = true;
    if (isMounted && user.username) {
      
      const updateServer = async (answer:any) => {
        const result = await API.graphql(
          graphqlOperation(createAnswer, {
            input: answer,
            authMode: "AMAZON_COGNITO_USER_POOLS",
          })
        );        
      }  

      const quiz_answers = Object.entries(localStorage)
        .map((e) => e[0])
        .filter((k) => k.startsWith("answers"));

      quiz_answers.forEach((key) =>{
        const answers = JSON.parse(localStorage.getItem(key) || "[]");
        answers.forEach((answer:any) => updateServer(answer));
      })

    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [user]);  


  React.useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      Auth.currentAuthenticatedUser().then((_user) => {
        setUser(_user);
      });
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, []);

    
  return (
    <React.Fragment>
      <AlbumLayout
        title={title}
        description={description}
        fluid={null}
        language={personas[0].language}
        carouselItems={carouselItems}
        pagePath={pagePath}
      >
        {personas.map((persona) => {
          if (persona.hidden.toLowerCase() === "true") {
            return null;
          }
          if (persona.id.endsWith("/intro")) {
            return null;
          }
          return (
            <Grid item key={persona.id} xs={12} sm={6} md={4}>
              <Link to={`/personas/${persona.language}/${persona.slug}`}>
                <Card className={classes.card}>
                  {/* https://www.reddit.com/r/gatsbyjs/comments/hlh9rz/gatsby_image_and_material_ui/fx2cjna/ */}
                  <Img
                    imgStyle={{ padding: "8px" }}
                    fluid={persona.imageFile[0].childImageSharp.fluid}
                  />

                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      {persona.name}
                    </Typography>
                    <Typography>{persona.description}</Typography>
                    <Typography>{persona.subdescription}</Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          );
        })}

      </AlbumLayout>
    </React.Fragment>
  );

}

export default Personas ;