/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      question_id
      quiz_id
      user_name
      user_avatar
      comment
      updatedAt
      createdAt
      owner
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      question_id
      quiz_id
      user_name
      user_avatar
      comment
      updatedAt
      createdAt
      owner
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      question_id
      quiz_id
      user_name
      user_avatar
      comment
      updatedAt
      createdAt
      owner
    }
  }
`;
export const createAggregations = /* GraphQL */ `
  mutation CreateAggregations(
    $input: CreateAggregationsInput!
    $condition: ModelAggregationsConditionInput
  ) {
    createAggregations(input: $input, condition: $condition) {
      id
      quiz_id
      comment_count
      like_count
      updatedAt
      createdAt
    }
  }
`;
export const updateAggregations = /* GraphQL */ `
  mutation UpdateAggregations(
    $input: UpdateAggregationsInput!
    $condition: ModelAggregationsConditionInput
  ) {
    updateAggregations(input: $input, condition: $condition) {
      id
      quiz_id
      comment_count
      like_count
      updatedAt
      createdAt
    }
  }
`;
export const deleteAggregations = /* GraphQL */ `
  mutation DeleteAggregations(
    $input: DeleteAggregationsInput!
    $condition: ModelAggregationsConditionInput
  ) {
    deleteAggregations(input: $input, condition: $condition) {
      id
      quiz_id
      comment_count
      like_count
      updatedAt
      createdAt
    }
  }
`;
export const createAnswer = /* GraphQL */ `
  mutation CreateAnswer(
    $input: CreateAnswerInput!
    $condition: ModelAnswerConditionInput
  ) {
    createAnswer(input: $input, condition: $condition) {
      id
      question_id
      quiz_id
      answer_id
      updatedAt
      createdAt
      owner
    }
  }
`;
export const updateAnswer = /* GraphQL */ `
  mutation UpdateAnswer(
    $input: UpdateAnswerInput!
    $condition: ModelAnswerConditionInput
  ) {
    updateAnswer(input: $input, condition: $condition) {
      id
      question_id
      quiz_id
      answer_id
      updatedAt
      createdAt
      owner
    }
  }
`;
export const deleteAnswer = /* GraphQL */ `
  mutation DeleteAnswer(
    $input: DeleteAnswerInput!
    $condition: ModelAnswerConditionInput
  ) {
    deleteAnswer(input: $input, condition: $condition) {
      id
      question_id
      quiz_id
      answer_id
      updatedAt
      createdAt
      owner
    }
  }
`;
